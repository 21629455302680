<template>
  <div>
    <v-app>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-date"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker v-if="modal2" locale="id" v-model="form.date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(form.date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Name input -->
        <b-form-group
          id="input-group-po-no"
          label="No PO:"
          label-for="input-po-no"
        >
          <b-form-input
            id="input-po-no"
            v-model="form.po_no"
            placeholder="No PO"
          ></b-form-input>
          <small class="text-danger">{{ error.po_no }}</small>
        </b-form-group>

        <!-- Name input -->
        <b-form-group id="input-group-vendor">
          <label for="input-vendor"
            >Kepada:</label
          >
          <treeselect
            v-model="form.vendor_id"
            :multiple="false"
            :options="vendors"
            @select="setVendor"
          />
          <small class="text-danger">{{ error.vendor_id }}</small>
        </b-form-group>

        <!-- Input Opening Sentence -->
        <b-form-group id="input-group-opening-sentence">
          <label for="input-opening-sentence"
            >Kalimat Pembuka: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-opening-sentence"
            v-model="form.opening_sentence"
            rows="3"
            placeholder="Kalimat Pembuka"
            @keyup="setOpeningSentence"
          ></b-form-textarea>
          <small class="text-danger">{{ error.opening_sentence }}</small>
        </b-form-group>

        <!-- Input Batch No -->
        <b-form-group id="input-group-batch-no">
          <label for="input-vendor">Item</label>
          <div class="row">
            <div class="col-md-3">
              <b-form-input
                id="input-batch-no"
                v-model="medicine_collection_form.item"
                placeholder="Nama Item"
              >
              </b-form-input>
              <!-- <small class="text-danger">{{ error_item.item }}</small> -->
            </div>
            <div class="col-md-3">
              <b-form-input
                id="input-quantity-batch"
                v-model="medicine_collection_form.quantity"
                placeholder="Kuantitas"
              >
              </b-form-input>
              <!-- <small class="text-danger">{{
                error_item.quantity
              }}</small> -->
            </div>
            <div class="col-md-3">
              <b-form-input
                id="input-price-batch"
                v-model="formatedSalesPrice"
                placeholder="Harga"
              >
              </b-form-input>
              <!-- <small class="text-danger">{{
                error_item.price
              }}</small> -->
            </div>
            <div class="col-md-3">
              <b-button
                size="sm"
                class="btn-info"
                v-b-tooltip.hover
                title="Tambah Batch"
                type="button"
                @click="setItem"
                v-if="medicine_collection_form.quantity != '' && medicine_collection_form.price != '' && medicine_collection_form.item != ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
              <b-button
                size="sm"
                class="btn-info"
                type="button"
                @click="setItem"
                disabled
                v-if="medicine_collection_form.quantity == '' || medicine_collection_form.price == '' || medicine_collection_form.item == ''"
                ><i class="fas fa-plus px-0"></i
              ></b-button>
            </div>
          </div>
          <small class="text-danger">{{ error.items }}</small>
        </b-form-group>

      <div class="bg-light">
        <table class="table">
          <tr>
            <th>No</th>
            <th>Items</th>
            <th>Quantity</th>
            <th>Price/case(IDR)</th>
            <th>Total Prices</th>
            <th>Aksi</th>
          </tr>
          <tr v-for="(mdc, index) in medicine_collection" :key="mdc.id">
            <td>{{index + 1}}</td>
            <td>{{ mdc.item }}</td>
            <td>{{ mdc.quantity }}</td>
            <td>{{ mdc.price }}</td>
            <td>{{ mdc.total_price }}</td>
            <td>
              <b-button
                size="sm"
                class="btn-danger"
                v-b-tooltip.hover
                title="Hapus Item"
                type="button"
                @click="removeItem(mdc.item)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </td>
          </tr>
        </table>
        </div>

        <!-- Input Discount -->
        <b-form-group
          id="input-group-discount"
          label="Diskon(%):"
          label-for="input-discount"
        >
          <b-form-input
            id="input-discount"
            v-model="form.discount"
            placeholder="Diskon"
          >
          </b-form-input>
          <small class="text-danger">{{ error.discount }}</small>
        </b-form-group>

        <!-- Input PPN -->
        <b-form-group
          id="input-group-ppn"
          label="PPN(%):"
          label-for="input-ppn"
        >
          <b-form-input id="input-ppn" v-model="form.ppn" placeholder="PPN">
          </b-form-input>
          <small class="text-danger">{{ error.ppn }}</small>
        </b-form-group>

        <!-- Input Closing Sentence -->
        <b-form-group id="input-group-closing-sentence">
          <label for="input-closing-sentence"
            >Kalimat Penutup: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-closing-sentence"
            v-model="form.closing_sentence"
            rows="3"
            placeholder="Kalimat Penutup"
          ></b-form-textarea>
          <small class="text-danger">{{ error.closing_sentence }}</small>
        </b-form-group>

        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/purchase-orders')"
        >
          Batal
        </b-button>
      </b-form>
    </v-app>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import ApiService from "@/core/services/api.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      modal2: false,
      // Error
      error: {
        date: "",
        po_no: "",
        vendor_id: "",
        vendor_name: "",
        items: "",
        discount: "",
        ppn: "",
        opening_sentence: "",
        closing_sentence: "",
      },
      // other
      vendors: [],
      medicine_collection: [],
      medicine_collection_str: [],
      medicine_collection_form: {
        item: "",
        quantity: "",
        price: "",
        total_price: "",
      },
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      tester: "",
      opening_sentence_write: false,
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    setOpeningSentence(){
      this.opening_sentence_write = true
    },

    getItemActive() {
      ApiService.get("purchase-orders/" + this.$route.params.id).then((response) => {
        this.medicine_active = JSON.parse(response.data.data.items)
        this.medicine_collection = JSON.parse(response.data.data.items)

        // this.displayMedicineName(JSON.parse(response.data.data.items));

      })
    },

    async getVendorOption() {
      let response = await module.setTreeSelect("vendors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.vendors = response.data;
        this.vendors.unshift({ label: 'Pilih Supplier', id: '', isDisabled: true })
      }
    },

    setVendor(evt){
      this.form.vendor_id = evt.id
      this.form.vendor_name = evt.label
      // if(this.opening_sentence_write == false){
      //   this.form.opening_sentence = `Sehubungan dengan surat penawaran harga (quotation) ${evt.label}, maka kami ingin memesan produk sebagai berikut :`
      // }
      // this.form.opening_sentence = `Sehubungan dengan surat penawaran harga (quotation) ${evt.label}, maka kami ingin memesan produk sebagai berikut :`
    },

    setItem(){
      // let price = parseInt(this.medicine_collection_form.price)
      // this.medicine_collection_form.total_price = this.medicine_collection_form.quantity * this.medicine_collection_form.price
      // console.log("yang akan di input", this.medicine_collection_form);
      // this.medicine_collection.push(this.medicine_collection_form);
      this.medicine_collection_form.total_price = this.medicine_collection_form.quantity * this.medicine_collection_form.price.replace(".", "")
      this.medicine_collection_form.total_price = parseInt(this.medicine_collection_form.total_price).toLocaleString("id-ID")
      let clone = { ...this.medicine_collection_form };
      this.medicine_collection.push(clone);
      this.resetItemOnNew()
    },

    resetItemOnNew(){
      this.medicine_collection_form.quantity = ""
      this.medicine_collection_form.price = ""
      this.medicine_collection_form.total_price = ""
      this.medicine_collection_form.item = ""
    },

    removeItem($item) {
      let a;
      for (a = 0; a < this.medicine_collection.length; a++) {
        if (this.medicine_collection[a].item == $item) {
          this.medicine_collection.splice(a, 1);
        }
      }
    },

    async formOnsubmit() {
      if(this.medicine_collection.length > 0){
      this.medicine_collection_str = await JSON.stringify(this.medicine_collection);
      this.form.items = await this.medicine_collection_str;
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/purchase-orders");
      }
      }else if(this.medicine_collection.length == 0){
        Swal.fire({
                title: "Gagal",
                text: "Item tidak boleh kosong",
                icon: "warning",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.error.items = "Item tidak boleh kosong"
      }
    },
  },

  mounted(){
    this.getVendorOption();
    this.getItemActive();
  },

  computed: {
    formatedSalesPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.medicine_collection_form.price = newValue.toLocaleString('id-ID')
        } else {
          this.medicine_collection_form.price = ''
        }
      },
      get() {
        return this.medicine_collection_form.price
      }
    }
  },
};
</script>

<style scoped>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>